import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, disconnect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { toChecksumAddress } from "ethereum-checksum-address";
import _ from "lodash";
import Web3 from 'web3';
const BN = Web3.utils.BN;

import signatures from "./assets/signatures.json";

import NFT1 from "./assets/platinum.png";
import NFT2 from "./assets/gold.png";
import NFT3 from "./assets/black.png";
import NFT4 from "./assets/green.png";


export const StyledButton = styled.button`
  padding: 10px 0;
  border-radius: 1rem;
  border: var(--secondary) 1px solid;
  background-color: var(--button-bg);
  color: var(--button-color);
  width: 250px;
  cursor: pointer;
  font-family: var(--font);
  font-size: 1.5rem;

  &:hover {
    background-color: rgba(22, 22, 22, 0.9);
  }

  &:active {
    transform: scale(0.95);
  }
`;


export const StyledRoundButton = styled.button`
  margin: 0 10px;
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--button-color);
  padding: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--var(--button-color););
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);

  &:hover {
    background-color: #c7ccc7;
  }

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    transform: scale(0.95);
  }

`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 300px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: transform 0.3s;
  
  &:hover {
    transform: scale(1.25);
  }
  &.rotate {
    transform: rotate(180deg);
  }
`;

function handleClick(event) {
    const image = event.target;
    image.classList.toggle('rotate');
}


export const StyledLink = styled.a`
  color: black;
  font-size: 2rem;
`;

function App() {

    const [approved, setApproved] = useState(false)
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click mint to buy your NFT.`);
    const [mintAmount, setMintAmount] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        TOKEN_CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        TOKEN_COST: 0,

        DISPLAY_COST: 0,
        DISPLAY_TOKEN_COST: 0,
        GAS_LIMIT: 0,
        TOKEN_GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });

    const mintPresaleEth = () => {
        const foundKey = _.findKey(
            signatures,
            (o, k) => _.toLower(k) === _.toLower(blockchain.account.toString())
        );

        let checksumAddr = toChecksumAddress(blockchain.account.toString());

        if (foundKey) {
            console.log(
                "* Setting amount allowed: " +
                signatures[checksumAddr]["amountAllowed"]
            );
        }
        else {
            alert(`Address ${blockchain.account} not found in WL form! If you think this is a mistake, contact the team in Discord`)
            return;
        }

        const voucher = signatures[foundKey];
        console.log(`Using signature: ${voucher["signature"]}`);
        // const amountAllowed = voucher["amountAllowed"];
        const nonce = voucher["nonce"];
        const SIGNATURE = voucher["signature"];

        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .whitelistMintETH(mintAmount, nonce, SIGNATURE)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong! Please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `${CONFIG.NFT_NAME} is yours! Check out OpenSea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const mintPublicEth = async () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        // let totalCostWei = String(cost * mintAmount);
        let totalCostWei = new BN(cost.toString()).mul(new BN(mintAmount.toString()));
        // let totalGasLimit = String(gasLimit * mintAmount);
        let totalGasLimit = String(gasLimit);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);

        // Estimate gas
        let gasEstimate = 0;
        try {
            gasEstimate = await blockchain.smartContract.methods.publicMintETH(mintAmount).estimateGas({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            });
        } catch (error) {
            console.log(error);
            alert(`The transaction simulation failed. Please check your wallet (10 mints max) and balance!`);
            return;
        }

        // If gas estimate is higher than gas limit, throw error. Also if 0.
        if (gasEstimate > gasLimit || gasEstimate === 0) {
            alert(`The transaction simulation failed. Please check your wallet (10 mints max) and balance!`);
            return;
        }


        console.log("Gas estimate: ", gasEstimate);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .publicMintETH(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong! Please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `${CONFIG.NFT_NAME} is yours! Go visit OpenSea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const mintPresaleToken = () => {
        const foundKey = _.findKey(
            signatures,
            (o, k) => _.toLower(k) === _.toLower(blockchain.account.toString())
        );

        let checksumAddr = toChecksumAddress(blockchain.account.toString());

        if (foundKey) {
            console.log(
                "* Setting amount allowed: " +
                signatures[checksumAddr]["amountAllowed"]
            );
        }
        else {
            alert(`Address ${blockchain.account} not found in WL form! If you think this is a mistake, contact the team in Discord`)
            return;
        }

        const voucher = signatures[foundKey];
        console.log(`Using signature: ${voucher["signature"]}`);
        // const amountAllowed = voucher["amountAllowed"];
        const nonce = voucher["nonce"];
        const SIGNATURE = voucher["signature"];

        // let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        // let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        // console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        blockchain.smartContract.methods
            .whitelistMintERC20(mintAmount, nonce, SIGNATURE)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong! Please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `${CONFIG.NFT_NAME} is yours! go visit OpenSea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
                window.localStorage.removeItem('approved')
                setApproved(false)
            });
    };

    const mintPublicToken = async () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        // let totalGasLimit = String(gasLimit * mintAmount);
        // Gas limit is the same for all mints since ERC721A is used
        let totalGasLimit = String(gasLimit);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        // Estimate gas
        let gasEstimate = 0;
        try {
            gasEstimate = await blockchain.smartContract.methods.publicMintERC20(mintAmount).estimateGas({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            });
        } catch (error) {
            console.log(error);
            alert(`The transaction simulation failed.`);
            return;
        }

        // If gas estimate is higher than gas limit, throw error. Also if 0.
        if (gasEstimate > gasLimit || gasEstimate === 0) {
            alert(`The transaction simulation failed. Please check your wallet (10 mints max) and $CNLT approvals. `);
            return;
        }
        blockchain.smartContract.methods
            .publicMintERC20(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong! Please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback(
                    `${CONFIG.NFT_NAME} is yours! go visit OpenSea.io to view it.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
                window.localStorage.removeItem('approved')
                setApproved(false)
            });
    };



    const approveTokens = () => {
        let gasLimit = CONFIG.TOKEN_GAS_LIMIT;
        // token approve totalGasLimit is constant
        let totalGasLimit = String(gasLimit);
        setFeedback(`Approving $CNLT... (don't refresh the page!)`);
        setClaimingNft(true);
        let approveAmount = new BN(CONFIG.TOKEN_COST.toString()).mul(new BN(mintAmount.toString()));
        console.log("Approve amount: ", approveAmount);
        blockchain.smartContractToken.methods.approve(CONFIG.CONTRACT_ADDRESS, approveAmount).send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account
        }).once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong! Please try again later.");
            setClaimingNft(false);
        }).then((receipt) => {
            console.log(receipt);
            setFeedback(
                `Approved $CNLT!`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
            setApproved(true);
            setLocalStorageApproved();
        });
    }

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 10) {
            newMintAmount = 10;
        }
        setMintAmount(newMintAmount);
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
            // setLocalStorageConnected()
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    const setLocalStorageConnected = () => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem('connected', 'injected');
        }
    }

    const setLocalStorageApproved = () => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem('approved', 'injected');
        }
    }

    useEffect(() => {
        getConfig();

        if (typeof window !== 'undefined') {
            if (window.localStorage.getItem('connected')) {

                console.log('Has local storage')
                getData();
                dispatch(connect());

            }
        }

        if (typeof window !== 'undefined') {
            if (window.localStorage.getItem('approved')) {

                console.log('Has approval')
                setApproved(true)
            }
        }

    }, []);

    useEffect(() => {

        getData();

        if (!blockchain.account) {
            window.localStorage.removeItem('connected')
        }

    }, [blockchain.account]);

    return (
        <s.Screen>

            <s.Container
                flex={1}
                ai={"center"}
                style={{ padding: 24 }}
            >

                <s.SpacerSmall />

                <ResponsiveWrapper className='mainWrapper' flex={1} style={{ padding: 24, width: '75%' }} test>

                    <s.SpacerSmall />

                    <s.Container
                        flex={2}
                        jc={"center"}
                        ai={"center"}
                        style={{
                            backgroundColor: "rgba(200,200,200,0.4)",
                            padding: 24,
                            borderRadius: 24,
                            backdropFilter: 'blur(10px)'
                        }}
                    >

                        <StyledLogo className="logo" alt={"logo"} src={"/config/images/logo.png"} />

                        <h1>WELCOME TO THE OG FOUNDERS CLUB</h1>

                        {/* <h2>Visit <a href="https://cannaland.xyz/420-nft" target="_blank" rel="noopener noreferrer">cannaland.xyz/420-nft</a> to RSVP for our first ever virtual event!</h2> */}


                        {
                            blockchain.account === null || blockchain.account === undefined || blockchain.account === '' ? null
                                :
                                <s.TextTitle
                                    style={{
                                        textAlign: "center",
                                        fontSize: 70,
                                        fontWeight: "bold",
                                        color: "var(--button-color)",
                                    }}
                                >
                                    {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                                </s.TextTitle>
                        }


                        <s.TextDescription
                            style={{
                                textAlign: "center",
                                color: "var(--primary-text)",
                            }}
                        >
                            <p style={{ color: 'var(--secondary)', fontSize: '2rem' }}>
                                Contract address: <br />
                            </p>
                            <StyledLink className="contractAddress" target={"_blank"} href={CONFIG.SCAN_LINK}>
                                {CONFIG.CONTRACT_ADDRESS}
                            </StyledLink>

                        </s.TextDescription>

                        <s.SpacerLarge />

                        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                            <>
                                <s.TextTitle
                                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                                >
                                    The sale has ended.
                                </s.TextTitle>
                                <s.TextDescription
                                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                                >
                                    You can still find {CONFIG.NFT_NAME} on
                                </s.TextDescription>
                                <s.SpacerSmall />
                                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                                    {CONFIG.MARKETPLACE}
                                </StyledLink>
                            </>
                        ) : (
                            <>
                                <div className="nftImages">

                                    <div>
                                        <StyledImg src={NFT1} onClick={handleClick} alt="" />
                                        {/* <img src={NFT1} alt="" /> */}
                                        <h2>5 Platinum GSC</h2>
                                    </div>
                                    <div>
                                        <StyledImg src={NFT2} onClick={handleClick} alt="" />
                                        {/* <img src={NFT2} alt="" /> */}
                                        <h2>50 Acapulco Gold</h2>

                                    </div>
                                    <div>
                                        <StyledImg src={NFT3} onClick={handleClick} alt="" />
                                        {/* <img src={NFT3} alt="" /> */}
                                        <h2>115 Black Haze</h2>

                                    </div>
                                    <div>
                                        <StyledImg src={NFT4} onClick={handleClick} alt="" />
                                        {/* <img src={NFT4} alt="" /> */}
                                        <h2>244 Northern Lights</h2>

                                    </div>

                                </div>
                                <div
                                    className="priceList"
                                    style={{ textAlign: "center", color: "var(--accent-text)", fontSize: '2.5rem' }}
                                >
                                    Price: <br />

                                    <div className="priceWrapper">

                                        <p style={{ color: 'blue' }}>

                                            {CONFIG.DISPLAY_COST * mintAmount}{" "}

                                            {CONFIG.NETWORK.SYMBOL}
                                        </p>

                                        <p style={{ margin: '0 1rem' }}>
                                            or
                                        </p>

                                        {/* <p style={{ color: 'var(--button-color)' }}>
                                            {CONFIG.DISPLAY_TOKEN_COST * mintAmount} $CNLT
                                        </p> */}

                                        <p>
                                            <span style={{ color: 'gold' }}>{CONFIG.DISPLAY_TOKEN_COST * mintAmount}</span>
                                            <span style={{ color: "gold" }}> $CNLT</span>
                                        </p>


                                    </div>

                                </div>
                                <s.SpacerXSmall />
                                <s.TextDescription
                                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                                >
                                    Excluding gas fees.
                                </s.TextDescription>
                                <s.SpacerSmall />
                                {blockchain.account === "" || blockchain.smartContract === null ? (
                                    <s.Container ai={"center"} jc={"center"}>
                                        <s.TextDescription
                                            style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                            }}
                                        >
                                            Connect to the {CONFIG.NETWORK.NAME} network
                                        </s.TextDescription>
                                        <s.SpacerSmall />
                                        <StyledButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(connect());
                                                getData();
                                                // alert("Please wait a few brief moment for the final site deployment...")
                                            }}
                                        >
                                            CONNECT
                                        </StyledButton>
                                        {blockchain.errorMsg !== "" ? (
                                            <>
                                                <s.SpacerSmall />
                                                <s.TextDescription
                                                    style={{
                                                        textAlign: "center",
                                                        color: "var(--accent-text)",
                                                    }}
                                                >
                                                    {blockchain.errorMsg}
                                                </s.TextDescription>
                                            </>
                                        ) : null}
                                    </s.Container>
                                ) : (
                                    <>
                                        <s.TextDescription
                                            style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                            }}
                                        >
                                            {feedback}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                            <StyledRoundButton
                                                style={{ lineHeight: 0.4 }}
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    decrementMintAmount();
                                                }}
                                            >
                                                -
                                            </StyledRoundButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                                style={{
                                                    textAlign: "center",
                                                    color: "var(--button-color)",
                                                    fontSize: '3rem'
                                                }}
                                            >
                                                {mintAmount}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledRoundButton
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    incrementMintAmount();
                                                }}
                                            >
                                                +
                                            </StyledRoundButton>
                                        </s.Container>
                                        <s.SpacerSmall />
                                        <s.Container ai={"center"} jc={"center"} fd={"column"}>

                                            <StyledButton
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    mintPublicEth();
                                                    // mintPresaleEth();
                                                    getData();
                                                }}
                                            >
                                                {claimingNft ? "BUSY" : "MINT"}
                                            </StyledButton>

                                            <s.SpacerMedium />


                                            {
                                                !approved ?
                                                    <StyledButton
                                                        disabled={claimingNft ? 1 : 0}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            approveTokens();
                                                            getData();
                                                        }}
                                                    >
                                                        {claimingNft ? "BUSY" : "APPROVE $CNLT"}
                                                    </StyledButton>
                                                    :
                                                    <StyledButton
                                                        disabled={claimingNft ? 1 : 0}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            mintPublicToken();
                                                            //mintPresaleToken();
                                                            // approveTokens();
                                                            getData();
                                                        }}
                                                    >
                                                        {claimingNft ? "BUSY" : "MINT WITH $CNLT"}
                                                    </StyledButton>
                                            }
                                            <s.SpacerSmall />
                                            <p className="connectedAccount" style={{ textAlign: 'center', fontSize: '1.5rem', marginTop: '1rem', opacity: "0.5" }}>
                                                Connected: {`${blockchain.account.slice(0, 6)}...${blockchain.account.slice(-4)}`}
                                            </p>

                                            {
                                                blockchain.account !== "" && blockchain.account !== null ? (
                                                    <StyledButton style={{ backgroundColor: "#00FFFF00", opacity: "0.5" }} onClick={(e) => {
                                                        e.preventDefault();
                                                        // disconnect();
                                                        dispatch(disconnect());
                                                        // window.localStorage.removeItem('connected')
                                                        // console.log("disconnecting...");
                                                        // window.location.reload();
                                                    }}>
                                                        DISCONNECT
                                                    </StyledButton>
                                                ) : (
                                                    <p>Not connected</p>
                                                )
                                            }

                                        </s.Container>
                                    </>
                                )}
                            </>
                        )}
                        <s.SpacerMedium />
                    </s.Container>


                </ResponsiveWrapper>

            </s.Container >

        </s.Screen >
    );
}

export default App;
