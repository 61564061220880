import Web3 from "web3";
import { fetchData } from "../data/dataActions";
import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";

import CONFIG from "./config.json";
import abi from "./abi.json";
import abiToken from "./erc20.json";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      // infuraId: infuraUrl || process.env.NEXT_PUBLIC_INFURA_URL,
      infuraId: "46a56bccabc471ebdfaa1e9b46a94f6",
    },
  },
  // metamask: {
  //   package: new Web3.providers.HttpProvider(
  //     "https://mainnet.infura.io/v3/46a56bccabc471ebdfaa1e9b46a94f6"
  //   ),
  //   display: {
  //     // logo: "path/to/your/logo",
  //     name: "Metamask",
  //     description: "Connect to Metamask"
  //   },
  //   options: {
  //     // custom options for the provider
  //   }
  // },

  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Cannaland NFT",
      infuraId: "46a56bccabc471ebdfaa1e9b46a94f6"
    }
  },

};

const web3Modal = new Web3Modal({
  cacheProvider: false,
  providerOptions,
});

const switchToNetwork = (networkId) => {
  return async (dispatch, getState) => {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);

    try {
      await web3.eth.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${networkId.toString(16)}` }],
      });
    } catch (error) {
      // Handle error
      console.error(error);
      return;
    }
    // Reload the page for the changes to take effect
    window.location.reload();
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());

    try {
      await web3Modal.clearCachedProvider();
      web3Modal.on("connect", async (provider) => {
        console.log("connected!!!!");
        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        const metamaskIsInstalled = web3Modal.cachedProvider !== null;

        if (metamaskIsInstalled && networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new web3.eth.Contract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          const SmartContractObjToken = new web3.eth.Contract(
            abiToken,
            CONFIG.TOKEN_CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              smartContractToken: SmartContractObjToken,
              web3: web3,
            })
          );
          // Add listeners start
          // web3Modal.on("accountsChanged", (accounts) => {
          //   console.log("accountsChanged");
          //   dispatch(updateAccount(accounts[0]));
          // });
          // web3Modal.on("chainChanged", () => {
          //   console.log("chainChanged");
          //   window.location.reload();
          // });
          provider.on("accountsChanged", (accounts) => {
            console.log("accountsChanged");
            // dispatch(updateAccount(accounts[0]));
            dispatch(disconnect());

            // Lets try this
            // dispatch(disconnectSuccess());
            window.location.reload();
          });
          provider.on("chainChanged", (chainId) => {
            console.log("chainChanged bro");
            dispatch(disconnect());
            window.location.reload();
          });
          provider.on("disconnect", (error) => {
            // web3Modal.clearCachedProvider();
            console.log("wallet wants to disconnect");
            dispatch(disconnect());
            dispatch(disconnectSuccess());
            window.location.reload();
          });
          // Add listeners end
        } else {
          console.log("network or wallet off");
          // switchToNetwork(CONFIG.NETWORK.ID);
          // dispatch(connectFailed("Install or connect a wallet that supports Ethereum"));
        }
      });
      await web3Modal.connect();
    } catch (error) {
      // console.log(error);
      window.location.reload();
    }
  };
};

// export const disconnect = () => {
//   return async (dispatch) => {
//     web3Modal.clearCachedProvider();
//     dispatch(connectFailed("Wallet disconnected"));
//     await web3Modal.dispatch(disconnect());
//   };
// };

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};

export const disconnect = () => {

  return async (dispatch, getState) => {
    console.log("disconnecting I guess...");
    // if (web3Modal.cachedProvider) {
    //   console.log("disconnecting cached provider");
    //   await web3Modal.clearCachedProvider();
    //   web3Modal.off("close");
    //   web3Modal.off("accountsChanged");
    //   web3Modal.off("chainChanged");
    //   dispatch(disconnectSuccess());
    //   // web3modal has a bug where it doesn't clear the cache

    // }
    await web3Modal.clearCachedProvider();
    window.localStorage.clear();
    window.location.reload();
    // console.log("Disconnecting Cached Provider");
    // await web3Modal.clearCachedProvider();
  };

};

const disconnectSuccess = () => {
  return {
    type: "DISCONNECT_SUCCESS",
  };
};
